<template>
  <layout :title="$route.name">
    <v-flex xs12 class="py-3 pb-0">
      <div class="expande-horizontal centaliza">
        <v-tabs v-model="tabs_sup" :color="$theme.secondary" centered>
          <v-badge
            class="mt-3 fonte-subtitulo fonte-italica"
            bordered
            :color="$theme.secondary"
            :content="`${getOpenByStatusAndType('mesa')}`"
            overlap
          >
            <v-btn
              @click="tabs_sup = 0"
              class="white--text mb-1 fonte-subtitulo fonte-italica"
              :class="tabs_sup === 0 ? 'white--text' : 'grey--text'"
              :color="tabs_sup === 0 ? $theme.secondary : 'transparent'"
              tile
              :dark="tabs_sup !== 0"
            >
              Mesa
            </v-btn>
          </v-badge>
          <v-badge
            class="mt-3 fonte-subtitulo fonte-italica"
            bordered
            :color="$theme.secondary"
            :content="`${getOpenByStatusAndType('delivery')}`"
            overlap
          >
            <v-btn
              class="fonte-subtitulo fonte-italica ml-3"
              @click="tabs_sup = 1"
              :class="tabs_sup === 1 ? 'white--text' : 'grey--text'"
              :color="tabs_sup === 1 ? $theme.secondary : 'transparent'"
              tile
            >
              Delivery
            </v-btn>
          </v-badge>
          <v-badge
            class="mt-3 fonte-subtitulo fonte-italica"
            bordered
            :color="$theme.secondary"
            :content="`${getOpenByStatusAndType('balcao')}`"
            overlap
          >
            <v-btn
              class="fonte-subtitulo fonte-italica ml-3"
              @click="tabs_sup = 2"
              :class="tabs_sup === 2 ? 'white--text' : 'grey--text'"
              :color="tabs_sup === 2 ? $theme.secondary : 'transparent'"
              tile
              :dark="tabs_sup !== 2"
            >
              Balcão
            </v-btn>
          </v-badge>
        </v-tabs>
      </div>
    </v-flex>
    <Mesa class="pa-3" v-if="tabs_sup === 0" />
    <Delivery class="pa-3" v-if="tabs_sup === 1" />
    <Balcao class="pa-3" v-if="tabs_sup === 2" />
    <ModalView />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Balcao from "../components/Balcao";
import Delivery from "../components/Delivery";
import Mesa from "../components/Mesa";
import ModalView from "../components/modalView";
export default {
  data() {
    return {
      tabs_sup: 2
    };
  },
  components: {
    Balcao,
    Mesa,
    Delivery,
    ModalView
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_transacoes"]),
  },
  methods: {
    ...mapActions(["listar_transacoes"]),
    getOpenByStatusAndType(tipo) {
      return this.get_transacoes.docs.filter(
        item => item.status_atual !== 'pago' && item.compra.tipo == tipo
      ).length;
    }
  },
  created() {
    this.listar_transacoes();
  }
};
</script>
