<template>
  <v-flex class="pa-3 pt-0" :style="`background: ${$theme.background};`" xs12>
    <div class="expande-horizontal">
      <!-- pendentes -->
      <v-flex class="pa-1" xs12 md3>
        <v-flex xs12 class="mb-2">
          <v-card bordered dark color="red">
            <v-flex xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <div class="expande-horizontal centraliza py-2 column">
                    <v-icon>mdi-alarm-light-outline</v-icon>
                    Pendentes
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-list
          class="dropzone-pendentes dropzone-container"
          v-if="getPendentes.length"
        >
          <template
            v-for="pedido in getPendentes"
            class="expande-horizontal mb-1"
          >
            <v-list-item :key="pedido._id">
              {{ pedido.status_atual }}
            </v-list-item>
          </template>
        </v-list>
        <v-flex v-else xs12>
          <div
            class="expande-horizontal dropzone-pendentes dropzone-container centraliza column pa-1"
          >
            <v-icon color="grey"> mdi-add </v-icon>
            <span class="fonte-default grey--text">Não há pedidos aqui</span>
          </div>
        </v-flex>
      </v-flex>
      <!-- Em preparo -->
      <v-flex class="pa-1" xs12 md3>
        <v-flex xs12>
          <v-card dark color="orange" class="mb-2">
            <v-flex xs12>
              <div class="expande-horizontal fonte wrap">
                <v-flex xs12>
                  <div class="expande-horizontal centraliza py-2 column">
                    <v-icon>mdi-clock-outline</v-icon>
                    Em preparo {{ getEmPreparo.length }}
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-list
          class="pa-0 ma-0 dropzone-empreparo dropzone-container"
          v-if="getEmPreparo.length"
          three-line
          style=""
        >
          <template
            v-for="pedido in getEmPreparo"
            class="expande-horizontal mb-1 dropzone-container"
          >
            <v-list-item
              @click="abre_modal_view_gestordepedido(pedido)"
              :key="pedido._id"
              :value="pedido"
              class="draggable item item-preparo animate__animated animate__fadeIn"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div class="expande-horizontal">
                    <v-icon></v-icon>
                  </div>
                </v-list-item-title>
                <v-list-item-title class="font-weight-bold fonte-default">
                  <div class="expande-horizontal">
                    #{{ pedido.id_transacao }}
                    <v-spacer></v-spacer>
                    <span
                      style="padding-right: 3px; padding-left: 3px; padding-top: 2px; background: #FFC107; border-radius: 5px; font-size: 9pt;"
                      class="fonte-subtitulo fonte-italica"
                    >
                      {{ $moment(pedido.created_at).fromNow() }}
                    </span>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  {{ pedido.compra.produtos.length }} itens
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica greenn--text font-weight-bold fonte-subtitulo"
                >
                  {{ $helper.formataSaldo(pedido.compra.preco_total) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  <v-icon size="12">mdi-account-circle</v-icon>
                  {{
                    pedido.compra.cliente
                      ? pedido.compra.cliente.nome
                      : "Cliente não informado"
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-flex class="dropzone-empreparo dropzone-container" v-else xs12>
          <div
            class="expande-horizontal dropzone-container centraliza column pa-1"
          >
            <v-icon color="grey"> mdi-add </v-icon>
            <span class="fonte-default grey--text">Não há pedidos aqui</span>
          </div>
        </v-flex>
      </v-flex>
      <!-- despachados -->
      <v-flex class="pa-1" xs12 md3>
        <v-flex xs12 class="mb-2">
          <v-card dark color="light-green">
            <v-flex xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <div class="expande-horizontal centraliza py-2 column">
                    <v-icon>mdi-moped-outline</v-icon>
                    Despachados
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-list
          nav
          class="pa-0 ma-0 dropzone-despachados"
          v-if="getDespachados.length"
        >
          <template
            v-for="pedido in getDespachados"
            class="expande-horizontal mb-1 dropzone-container"
          >
            <v-list-item
              @click="abre_modal_view_gestordepedido(pedido)"
              :key="pedido._id"
              :value="pedido"
              class="draggable item item-preparo animate__animated animate__fadeIn"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div class="expande-horizontal">
                    <v-icon></v-icon>
                  </div>
                </v-list-item-title>
                <v-list-item-title class="font-weight-bold fonte-default">
                  <div class="expande-horizontal">
                    #{{ pedido.id_transacao }}
                    <v-spacer></v-spacer>
                    <span
                      style="padding-right: 3px; padding-left: 3px; padding-top: 2px; background: #FFC107; border-radius: 5px; font-size: 9pt;"
                      class="fonte-subtitulo fonte-italica"
                    >
                      {{ $moment(pedido.created_at).fromNow() }}
                    </span>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  {{ pedido.compra.produtos.length }} itens
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica greenn--text font-weight-bold fonte-subtitulo"
                >
                  {{ $helper.formataSaldo(pedido.compra.preco_total) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  <v-icon size="12">mdi-account-circle</v-icon>
                  {{
                    pedido.compra.cliente
                      ? pedido.compra.cliente.nome
                      : "Cliente não informado"
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-flex v-else xs12>
          <div
            class="dropzone-despachados expande-horizontal dropzone-container centraliza column pa-1"
            style="height: 60vh; border: 3px solid #f2f2f2; border-radius: 5px;"
          >
            <v-icon color="grey"> mdi-add </v-icon>
            <span class="fonte-default grey--text">Não há pedidos aqui</span>
          </div>
        </v-flex>
      </v-flex>
      <!-- finalizados -->
      <v-flex class="pa-1" xs12 md3>
        <v-flex xs12 class="">
          <v-card dark color="green">
            <v-flex xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <div class="expande-horizontal centraliza py-2 column">
                    <v-icon>mdi-check-circle-outline</v-icon>
                    Concluídos
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-list
          class="dropzone-container dropzone-finalizados"
          v-if="getfinalizados.length"
        >
          <template
            v-for="pedido in getfinalizados"
            class="expande-horizontal mb-1 dropzone-container"
          >
            <v-list-item
              @click="abre_modal_view_gestordepedido(pedido)"
              :key="pedido._id"
              :value="pedido"
              class="draggable item item-preparo animate__animated animate__fadeIn"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <div class="expande-horizontal">
                    <v-icon></v-icon>
                  </div>
                </v-list-item-title>
                <v-list-item-title class="font-weight-bold fonte-default">
                  <div class="expande-horizontal">
                    #{{ pedido.id_transacao }}
                    <v-spacer></v-spacer>
                    <span
                      style="padding-right: 3px; padding-left: 3px; padding-top: 2px; background: #FFC107; border-radius: 5px; font-size: 9pt;"
                      class="fonte-subtitulo fonte-italica"
                    >
                      {{ $moment(pedido.created_at).fromNow() }}
                    </span>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  {{ pedido.compra.produtos.length }} itens
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica greenn--text font-weight-bold fonte-subtitulo"
                >
                  {{ $helper.formataSaldo(pedido.compra.preco_total) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="fonte-italica font-weight-bold fonte-subtitulo"
                >
                  <v-icon size="12">mdi-account-circle</v-icon>
                  {{
                    pedido.compra.cliente
                      ? pedido.compra.cliente.nome
                      : "Cliente não informado"
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-flex class="dropzone-finalizados" v-else xs12>
          <div
            class="expande-horizontal dropzone-container centraliza column pa-1"
          >
            <v-icon color="grey"> mdi-add </v-icon>
            <span class="fonte-default grey--text">Não há pedidos aqui</span>
          </div>
        </v-flex>
      </v-flex>
    </div>
    <ModalView />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalView from "../components/modalView.vue";
import Bus from "@/apps/shared/assets/js/EventBus.js";
import { EventBus } from "@/main.js";
import InteractJS from "interactjs";

export default {
  components: {
    ModalView
  },
  data() {
    return {
      modal_escolha_template: false,
      item: null,
      template_escolhido: null
    };
  },
  props: ["transacoes", "status_atual", "tipo"],
  computed: {
    ...mapGetters(["get_transacoes", "get_transacao"]),
    getPendentes() {
      const pendentes = this.get_transacoes.docs.filter(
        item => item.status_atual === "pendente" && item.compra.tipo === this.tipo
      );
      return pendentes;
    },
    getEmPreparo() {
      const pedidos = this.get_transacoes.docs.filter(
        item => item.status_atual === "Em preparo" && item.compra.tipo === this.tipo
      );
      return pedidos;
    },
    getDespachados() {
      const pedidos = this.get_transacoes.docs.filter(
        item => item.status_atual === "despachado" && item.compra.tipo === this.tipo
      );
      return pedidos;
    },
    getfinalizados() {
      const finalizados = this.get_transacoes.docs.filter(
        item => item.status_atual === "finalizado" && item.compra.tipo === this.tipo
      );
      return finalizados;
    }
  },
  methods: {
    ...mapActions([
      "abre_modal_view_gestordepedido",
      "createConfirmAction",
      "createGlobalMessage",
      "update_status_compra"
    ]),
    initDropZones() {
      let self = this;

      InteractJS(".dropzone-pendentes").dropzone({
        // need a 10% overlap to count as 'droppable'
        overlap: 0.1,

        // only accept draggable elements
        accept: ".draggable",

        // highlight all the zones that can be dropped in
        ondropactivate: function(event) {
          // add active dropzone feedback
          event.target.classList.add("drop-active");
        },

        // when a component is hovering inside a dropzone
        ondragenter: function(event) {
          var draggableElement = event.relatedTarget,
            dropzoneElement = event.target;

          // feedback the possibility of a drop: green component, blue zone
          dropzoneElement.classList.add("drop-target");
          draggableElement.classList.add("can-drop");
        },

        // when a component is no longer hovering a dropzone
        ondragleave: function(event) {
          // remove the drop feedback style
          event.target.classList.remove("drop-target");
          event.relatedTarget.classList.remove("can-drop");
        },

        // when a component has been dropped in a dropzone
        ondrop: function(event) {},

        // the dragging and dropping motion is over
        ondropdeactivate: function(event) {
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-target");
        }
      });
      InteractJS(".dropzone-empreparo").dropzone({
        // need a 10% overlap to count as 'droppable'
        overlap: 0.1,

        // only accept draggable elements
        accept: ".draggable",

        // highlight all the zones that can be dropped in
        ondropactivate: function(event) {
          // add active dropzone feedback
          event.target.classList.add("drop-active");
        },

        // when a component is hovering inside a dropzone
        ondragenter: function(event) {
          var draggableElement = event.relatedTarget,
            dropzoneElement = event.target;

          // feedback the possibility of a drop: green component, blue zone
          dropzoneElement.classList.add("drop-target");
          draggableElement.classList.add("can-drop");
        },

        // when a component is no longer hovering a dropzone
        ondragleave: function(event) {
          // remove the drop feedback style
          event.target.classList.remove("drop-target");
          event.relatedTarget.classList.remove("can-drop");
        },

        // when a component has been dropped in a dropzone
        ondrop: function(event) {
          const { relatedTarget } = event;
          const compra = relatedTarget.__vue__.value;
          self.$store.commit("set_transacao", compra);
          self.get_transacao.newStatus = "Em preparo";
          self.update_status_compra();
          Bus.fire("component-dropped", event);
          event.relatedTarget.classList.remove("can-drop");
        },

        // the dragging and dropping motion is over
        ondropdeactivate: function(event) {
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-target");
        }
      });
      InteractJS(".dropzone-despachados").dropzone({
        // need a 10% overlap to count as 'droppable'
        overlap: 0.1,

        // only accept draggable elements
        accept: ".draggable",

        // highlight all the zones that can be dropped in
        ondropactivate: function(event) {
          // add active dropzone feedback
          event.target.classList.add("drop-active");
        },

        // when a component is hovering inside a dropzone
        ondragenter: function(event) {
          var draggableElement = event.relatedTarget,
            dropzoneElement = event.target;

          // feedback the possibility of a drop: green component, blue zone
          dropzoneElement.classList.add("drop-target");
          draggableElement.classList.add("can-drop");
        },

        // when a component is no longer hovering a dropzone
        ondragleave: function(event) {
          // remove the drop feedback style
          event.target.classList.remove("drop-target");
          event.relatedTarget.classList.remove("can-drop");
        },

        // when a component has been dropped in a dropzone
        ondrop: function(event) {
          const { relatedTarget } = event;
          const compra = relatedTarget.__vue__.value;
          self.$store.commit("set_transacao", compra);
          self.get_transacao.newStatus = "despachado";
          self.update_status_compra();
          Bus.fire("component-dropped", event);
          event.relatedTarget.classList.remove("can-drop");
        },

        // the dragging and dropping motion is over
        ondropdeactivate: function(event) {
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-target");
        }
      });
      InteractJS(".dropzone-finalizados").dropzone({
        // need a 10% overlap to count as 'droppable'
        overlap: 0.1,

        // only accept draggable elements
        accept: ".draggable",

        // highlight all the zones that can be dropped in
        ondropactivate: function(event) {
          // add active dropzone feedback
          event.target.classList.add("drop-active");
        },

        // when a component is hovering inside a dropzone
        ondragenter: function(event) {
          var draggableElement = event.relatedTarget,
            dropzoneElement = event.target;

          // feedback the possibility of a drop: green component, blue zone
          dropzoneElement.classList.add("drop-target");
          draggableElement.classList.add("can-drop");
        },

        // when a component is no longer hovering a dropzone
        ondragleave: function(event) {
          // remove the drop feedback style
          event.target.classList.remove("drop-target");
          event.relatedTarget.classList.remove("can-drop");
        },

        // when a component has been dropped in a dropzone
        ondrop: function(event) {
          const { relatedTarget } = event;
          const compra = relatedTarget.__vue__.value;
          self.$store.commit("set_transacao", compra);
          self.get_transacao.newStatus = "finalizado";
          self.update_status_compra();
          Bus.fire("component-dropped", event);
          event.relatedTarget.classList.remove("can-drop");
        },

        // the dragging and dropping motion is over
        ondropdeactivate: function(event) {
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-target");
        }
      });
    },
    makeComponentsDraggable() {
      let self = this;
      console.log("InteractJS", InteractJS);

      InteractJS(".draggable").draggable({
        // call this function on every dragmove event
        onmove: dragMoveListener,

        // when a component is no longer being dragged,
        // snap the component back to where it started
        onend: function(event) {
          var target = event.target;
          target.style.webkitTransform = target.style.transform =
            "translate(0px, 0px)";

          target.classList.remove("moving");

          target.setAttribute("data-x", 0);
          target.setAttribute("data-y", 0);
        }
      });

      // when a component moves, track the motion in data attributes and update visually
      // using CSS translate attributes
      function dragMoveListener(event) {
        var target = event.target;

        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

        target.classList.add("moving");

        // translate the element with CSS
        target.style.webkitTransform = target.style.transform =
          "translate(" + x + "px, " + y + "px)";

        // update the position attributes
        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
      }

      window.dragMoveListener = dragMoveListener;
    }
  },
  mounted() {
    this.makeComponentsDraggable();
    this.initDropZones();
    EventBus.$on("status_updated", () => {
      this.$forceUpdate();
    });
  }
};
</script>

<style scoped>
.item {
  border-radius: 5px;
  width: 100%;
}
.item-preparo {
  border: 3px solid #f2f2f2;
  margin-bottom: 6px;
}

.dropzone-pendentes {
  /* background-color: #bfe4ff; */
  border: dashed 4px transparent;
  transition: background-color 0.3s;
}
.dropzone-empreparo {
  /* background-color: #bfe4ff; */
  border: dashed 4px transparent;
  transition: background-color 0.3s;
}
.dropzone-despachados {
  /* background-color: #bfe4ff; */
  border: dashed 4px transparent;
  transition: background-color 0.3s;
}
.dropzone-finalizados {
  /* background-color: #bfe4ff; */
  border: dashed 4px transparent;
  transition: background-color 0.3s;
}

.drop-active {
  border: dashed 4px #4bc147;
}

.drop-target:hover {
  background: #c3e8c0;
}

.drag-drop {
  touch-action: none;
  transform: translate(10px, 0px);

  transition: background-color 0.3s;
}

.drag-drop.can-drop {
  color: #000;
  background-color: #4e4;
}

.dropzone-container {
  min-height: 60vh;
  max-height: 60vh;
  /* border: 3px solid #f2f2f2; */
  border-radius: 5px;
}
</style>
