<template>
  <v-card color="transparent" class="pt-0 elevation-0 expande-horizontal wrap">
    <div class="expande-horizontal wrap pb-0">
      <v-flex xs12>
        <div class="expande-horizontal wrap">
          <v-flex xs12>
            <VisualizacaoEmLista tipo="delivery" :status_atual="status_atual" />
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import VisualizacaoEmLista from "./VisualizacaoEmLista";
export default {
  components: { ModalSendArchive, VisualizacaoEmLista },
  data() {
    return {
      novo_link_dialog: false,
      novo_link_content: {},
      tabs: 0,
      tabs_sup: 0,
      status_atual: "pendentes"
    };
  },
  computed: {
    ...mapGetters(["get_transacoes", "getLinks"])
  },
  methods: {
    openLink(item) {
      window.open(item.link, "_blank");
    }
  }
};
</script>

<style>
.cel {
  width: 300px;
  height: 620px;
  border-right: 2px solid #333;
  border-left: 2px solid #333;
  border-radius: 6px;
  border-top: 12px solid #333;
  border-bottom: 18px solid #333;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #c480ff, #8e2de2);
}
.item-list {
  border-radius: 6px;
  background-color: #fff;
}
.item {
  width: 100px;
  height: 100px;
}
.blue {
  background-color: #8e2de2;
}
.transitionBackground {
  transition: background-color 1s;
  transition: border-radius 1s;
}
.transitionBackground:hover {
  background-color: #57ff24;
  border-radius: 5px;
}
.transitionSize {
  transition: z-index 1s;
  z-index: 1000000;
}
.transitionZIndex {
  transition: transform 1s;
}
.transitionSize:hover {
  transform: scale(1.5);
}
</style>
