<template>
  <v-dialog
    fullscreen
    persistent
    v-model="get_modal_view_gestordepedido"
    style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
  >
    <div
      class="expande-horizontal centraliza"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    >
      <v-card width="800" class="expande-horizontal wrap">
        <div class="expande-horizontal px-1 pr-3 py-3 pb-0">
          <v-btn dark @click="fecha_modal_view_gestordepedido" icon>
            <v-icon color="orange">
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <span
            style="font-size: 17pt; width: 100%;"
            class="fonte-subtitulo font-weight-bold fonte-italica"
          >
            #{{ get_gestordepedido.id_transacao }}
          </span>
          <span
            style="background: orange; border-radius: 5px; width: 220px; color: #fff; display: flex;"
            class="mr-2 centraliza fonte-subtitulo fonte-italica font-weight-bold"
            >Aberto {{ $moment(get_gestordepedido.created_at).fromNow() }}
          </span>
          <span
            style="background: green; border-radius: 5px; width: 170px; color: #fff; display: flex;"
            class="mr-2 centraliza fonte-subtitulo fonte-italica font-weight-bold"
            >{{ get_gestordepedido.status_atual }}
          </span>
          <v-btn icon @click="fecha_modal_view_gestordepedido">
            <v-icon color="orange">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="expande-horizontal pa-3 wrap">
          <v-flex xs12>
            <div class="expande-horizontal wrap">
              <v-flex class="pa-1" xs12 md4>
                <div class="expande-horizontal wrap">
                  <v-flex xs12>
                    <v-card min-height="130" outlined>
                      <v-flex xs12>
                        <div class="pb-2 pl-2 expande-horizontal">
                          <v-icon color="light-green" size="13" class="mr-1"
                            >mdi-account-circle</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica">{{
                            get_gestordepedido.compra.cliente.nome
                          }}</span>
                        </div>
                        <div class="pb-2 pl-2 expande-horizontal">
                          <v-icon color="light-green" size="13" class="mr-1"
                            >mdi-cash</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica">{{
                            $helper.formataSaldo(
                              get_gestordepedido.compra.preco_total
                            )
                          }}</span>
                        </div>
                        <div
                          v-if="get_gestordepedido.compra.acrescimo"
                          class="pb-2 pl-2 expande-horizontal"
                        >
                          <v-icon color="green" size="13" class="mr-1"
                            >mdi-cash</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica">{{
                            get_gestordepedido.compra.acrescimo
                          }}</span>
                        </div>
                        <div
                          v-if="get_gestordepedido.compra.desconto"
                          class="pb-2 pl-2 expande-horizontal"
                        >
                          <v-icon color="red" size="13" class="mr-1"
                            >mdi-cash</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica">{{
                            get_gestordepedido.compra.desconto
                          }}</span>
                        </div>
                      </v-flex>
                    </v-card>
                  </v-flex>
                </div>
              </v-flex>
              <v-flex class="pa-1" xs12 md4>
                <div class="expande-horizontal wrap">
                  <v-flex xs12>
                    <v-card min-height="130" outlined>
                      <v-flex xs12>
                        <div class="pl-2 pb-2 expande-horizontal">
                          <v-icon color="light-green" size="13" class="mr-1"
                            >mdi-cash-register</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica"
                            >Caixa:
                            {{
                              get_gestordepedido.caixa.funcionario.nome
                            }}</span
                          >
                        </div>
                        <div class="expande-horizontal pl-2 pb-2">
                          <v-btn
                            text
                            class="fonte-subtitulo"
                            x-small
                            color="green"
                            rounded
                            dark
                          >
                            Abrir no pdv
                            <v-icon size="16">mdi-call-made</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-card>
                  </v-flex>
                </div>
              </v-flex>
              <v-flex class="pa-1" xs12 md4>
                <div class="expande-horizontal wrap">
                  <v-flex xs12>
                    <v-card min-height="130" outlined>
                      <v-flex xs12>
                        <div class="pa-2 expande-horizontal">
                          <v-icon size="13" color="light-green" class="mr-1"
                            >mdi-ballot</v-icon
                          >
                          <span class="fonte-subtitulo fonte-italica">
                            Andamento do pedido:
                          </span>
                        </div>
                        <div
                          class="expande-horizontal"
                          v-for="status in get_gestordepedido.status"
                          :key="status.status"
                        >
                          <v-icon
                            size="13"
                            color="light-green"
                            class="mr-1 ml-2"
                            >mdi-chevron-right</v-icon
                          >
                          <span
                            class="fonte-subtitulo fonte-italica fonte-micro"
                          >
                            {{ status.status }} -
                            {{
                              $moment(status.data).format("DD/MM/YY [às] HH:mm")
                            }}
                          </span>
                        </div>
                      </v-flex>
                    </v-card>
                  </v-flex>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal">
              <v-timeline dense>
                <v-timeline-item
                  v-for="item in get_gestordepedido.compra.produtos"
                  :key="item._id"
                  small
                  fill-dot
                  color="orange"
                >
                  <div class="expande-horizontal column">
                    <span
                      class="fonte-subtitulo font-weight-bold fonte-italica"
                    >
                      Produto: {{ item.nome }}
                    </span>
                    <span class="fonte-subtitulo fonte-italica">
                      Obs: {{ item.obs || "Sem obs" }}
                    </span>
                    <span class="fonte-subtitulo fonte-italica green--text">
                      + {{ item | getPriceFilter }}
                    </span>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-flex>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "get_gestordepedido",
      "get_modal_view_gestordepedido",
      "getLoggedUser"
    ]),
    nomeCliente() {
      if (
        this.get_gestordepedido.cliente &&
        this.get_gestordepedido.cliente.nome
      )
        return this.get_gestordepedido.cliente.nome;
      return "---";
    }
  },
  filters: {
    getPriceFilter(val) {
      let price = 0;
      if (val.tipo_de_preco === "unico") {
        price = val.preco_unico;
        return price;
      } else if (val.tipo_de_preco === "multiplo") {
        const precoEscolhido = val.preco_multiplo.filter(preco => preco.marked);
        price = precoEscolhido[0].preco;
        return price;
      }
      return price;
    }
  },
  methods: {
    ...mapActions([
      "criar_gestordepedido",
      "atualizar_gestordepedido",
      "fecha_modal_view_gestordepedido"
    ])
  }
};
</script>
